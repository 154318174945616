@import 'assets/media_variables.scss';

.container {
  display: flex;
  color: white;
  justify-content: space-between;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
    padding: 0 25px;
  }

  .right {
    @media screen and (max-width: $screen-md-max) {
      margin-top: 45px;
    }
  }

  .left,
  .right {
    width: 48%;

    @media screen and (max-width: $screen-md-max) {
      width: unset;
    }

    & > .title {
      max-width: 100%;
      text-transform: uppercase;
      font-family: 'Roboto-Bold';
      margin-bottom: 30px;
      font-size: 48px;
      padding-left: 25px;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 45px;
        line-height: 45px;
        word-spacing: 1000px;
        padding-left: unset;
      }
    }
  }
}

.insight {
  display: flex;
  flex-direction: column;
  line-height: 26px;
  background-color: #1c1c1c;
  border-radius: 6px;
  box-shadow: 3px 3px 10px black;
  padding: 30px;
  margin-bottom: 20px;

  @media screen and (max-width: $screen-md-max) {
    background-color: unset;
    box-shadow: unset;
    padding: unset;
  }

  & > .title {
    text-transform: uppercase;
    font-family: 'Roboto-Bold';
    color: #f9b233;
    margin-bottom: 15.5px;
    font-size: 16px;
  }

  & > .content {
    min-height: 50px;
  }
}
