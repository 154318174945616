.container {
  color: white;
  line-height: 26px;
  max-width: 1000px;
  height: 100%;

  .title {
    padding-bottom: 2rem;
    font-size: 40px;
    line-height: 1;
  }
}
