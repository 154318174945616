@import 'assets/media_variables.scss';
@import 'assets/colors.scss';

.container {
  max-height: 60px;
  user-select: none;
  position: relative;
  width: 550px;

  &.desktop {
    @media screen and (max-width: $screen-md-max) {
      display: none;
    }
  }

  &.mobile {
    @media screen and (min-width: $screen-md-max) {
      display: none;
    }
  }

  &.active {
    overflow: unset;
    padding-bottom: 50px;
  }

  &.disabled {
    opacity: 0.3;
  }

  .markerContainer {
    width: 495px;
    height: 0;

    .emptyContainer {
      width: 35px;
    }

    .marker {
      width: 0;
      height: 0;
      position: relative;
      animation: slideFromLeft 1s;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-top: 30px solid $green;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;

      &.blue {
        border-top-color: $blue;
      }
      &.green {
        border-top-color: $green;
      }
      &.pink {
        border-top-color: $pink;
      }
      &.orange {
        border-top-color: $orange;
      }
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
  }

  .leftText,
  .rightText {
    color: white;
    font-size: 12px;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    overflow: visible;
  }
}

@keyframes slideFromLeft {
  0% {
    margin-bottom: 10px;
    border-color: transparent;
    border-top-color: white;
    left: 0%;
  }
}
