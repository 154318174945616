.tab {
  cursor: pointer;
  padding: 15px 0px;
  text-transform: uppercase;
  color: white;
  font-family: 'Roboto-Regular';
  margin: 0 10px;
  font-size: 16px;
  user-select: none;
  display: flex;
  line-height: 16px;

  .icon {
    margin-top: -1px;
    height: 16px;
    margin-right: 5px;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &.active {
    color: white;
    font-family: 'Roboto-Bold';
    border-bottom: 2px solid white;
  }
}
