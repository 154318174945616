@import 'assets/colors.scss';
@import 'assets/media_variables.scss';

.container {
  min-height: 100vh;
  padding-top: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 100%;
  max-width: 380px;

  @media screen and (max-width: $screen-md-max) {
    padding: 130px 10px 50px 10px;
  }

  .logo {
    max-width: 250px;
  }

  .form {
    max-width: 380px;
    width: 100%;

    .select {
      display: block;
      border-radius: 20px !important;
      font-size: 15px;
      width: 100%;
      margin-top: 20px;
      outline: none;
      color: white !important;
      background-color: transparent !important;
      border: 1px solid #f4f4f4 !important;

      & > div,
      & > input {
        color: white !important;
      }

      input {
        background-color: transparent !important;
      }

      & > div {
        color: white !important;
      }
    }

    .submit {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 0px;
      background-color: $orange;
      font-size: 17px;
      font-weight: bold;
      color: white;
      cursor: pointer;
      margin-top: 20px;

      &:disabled {
        cursor: not-allowed;
        filter: brightness(0.7);
      }
    }
  }
  .cancel {
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    font-size: 14px;
    color: white;
  }
}
