@import 'assets/media_variables.scss';

.container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
    width: 100%;
  }

  .left {
    width: 45%;

    @media screen and (max-width: $screen-md-max) {
      width: 100%;
      margin-bottom: 20px;
    }

    .aqText {
      width: 100%;
      white-space: pre-wrap;
    }
    .chart {
      transform: scale(1.3);

      @media screen and (max-width: $screen-md-max) {
        transform: unset;
      }

      @media screen and (max-width: $screen-xs-max) {
        margin-bottom: -75px;
      }
    }
  }

  .right {
    width: 45%;

    @media screen and (max-width: $screen-md-max) {
      width: 100%;
    }

    .item {
      margin-bottom: 15px;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 20px;
      }

      .progressBar {
        margin-top: 35px;
      }
    }
  }
}
