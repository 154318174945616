.progressBarContainer {
    position:fixed;
    bottom: 0;
    z-index: 20;
    max-width: 520px;
    width: calc(100% - 130px)
}

.botProgressBar {
    height: 28px !important;
}