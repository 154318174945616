@import 'assets/media_variables.scss';

.container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
  }

  .left,
  .right {
    width: 45%;

    @media screen and (max-width: $screen-md-max) {
      width: unset;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .item {
    margin-bottom: 15px;

    @media screen and (max-width: $screen-md-max) {
      margin-bottom: 20px;
    }

    .progressBar {
      margin-top: 35px;
    }

    .lineChart {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 500px;
      transform: scale(0.75);

      @media screen and (max-width: $screen-md-max) {
        transform: scale(1.3);
        width: unset;
      }

      @media screen and (max-width: $screen-sm-max) {
        transform: scale(1.2);
        margin-top: 2rem;
      }

      @media screen and (max-width: $screen-xs-max) {
        transform: scale(0.6);
        margin-top: 2rem;
      }
    }
  }
}
