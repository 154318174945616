@import 'assets/media_variables.scss';

.container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .left {
    flex-basis: 50%;

    .title {
      margin-top: 65px;
      margin-bottom: 45px;

      @media screen and (max-width: $screen-md-max) {
        margin-top: 15px;
      }
    }

    .description {
      max-width: 460px;
      white-space: pre-wrap;

      @media screen and (max-width: $screen-md-max) {
        max-width: unset;
      }
    }
  }

  .right {
    flex-basis: 50%;

    .chart {
      @media screen and (max-width: $screen-md-max) {
        margin-top: -25px;
        margin-bottom: -50px;
      }

      @media screen and (max-width: $screen-xs-max) {
        margin-bottom: -90px;
      }
    }
  }
}
