@import 'assets/colors.scss';

.checkbox > label {
  margin-top: 20px;
  color: white !important;
  font-size: 14px;
  font-family: 'Roboto-Regular';

  .link {
    font-family: 'Roboto-Bold';
    color: $orange;
  }
}
.checkboxErrors {
  color: red;
  padding: 5px 10px 0 10px;
}
