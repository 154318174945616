@import 'assets/colors.scss';
@import 'assets/media_variables.scss';

.container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
  }

  .left,
  .right {
    flex-basis: 45%;

    .title {
      word-spacing: 1000px;
      margin-bottom: 30px;

      @media screen and (max-width: $screen-md-max) {
        font-size: 48px;
        line-height: 45px;
        text-transform: uppercase;
        margin-bottom: 45px;
      }
    }

    .boxTitle {
      text-transform: uppercase;
      line-height: 26px;
      color: #f9b233;
      font-family: 'Roboto-Bold';
    }

    .boxContent {
      margin-top: 16px;
      margin-bottom: 30px;

      @media screen and (max-width: $screen-md-max) {
        margin-top: 25px;
        margin-bottom: 46px;
      }
    }
  }
}
