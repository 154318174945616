.container {
  height: 500px;
  width: 500px;
  cursor: pointer;
  box-shadow: 0px 0px 10px black;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .title {
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
  }

  .chart {
    height: 100%;
    font-size: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow {
    align-self: flex-end;
    font-size: 20px;
  }
}
