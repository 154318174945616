@import 'assets/media_variables.scss';

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  color: white;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  padding-bottom: 20px;
  z-index: 2;
}
.form {
  z-index: 2;
  max-width: 380px;

  .field {
    width: 100%;
    color: white;
    display: block;
    border-radius: 20px;
    padding: 12px 24px;
    border: 1px solid #f4f4f4;
    font-size: 16px;
    margin-bottom: 20px;
    background-color: transparent !important;
    outline: none;

    &::placeholder {
      color: white;
    }

    &:focus {
      box-shadow: 0 0 3px #ddd;
    }
  }

  .submit {
    margin-top: 20px;
    text-transform: uppercase;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 0px;
    background-color: #f9b233;
    font-size: 16px;
    color: white;
    cursor: pointer;
  }

  .cancel {
    margin-top: 10px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    text-decoration: underline;
    text-align: center;
  }
}
