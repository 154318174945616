@import 'assets/media_variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  padding-top: 65px;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column-reverse;
    padding-top: unset;
  }

  .left {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;

    & > .title {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
      white-space: nowrap;
      @media screen and (max-width: $screen-md-max) {
        display: none;
      }

      .titleIcon {
        height: 60px;
        margin-right: 10px;
      }
    }

    .progressBarAdditional {
      @media screen and (min-width: $screen-md-max) {
        display: none;
      }
      .title {
        margin-bottom: 20px;
      }
    }
    .progressBar {
      margin-bottom: 10px;

      @media screen and (max-width: $screen-md-max) {
        margin-top: 25px;
        margin-bottom: 60px;
      }
    }
  }

  .right {
    flex-basis: 45%;
    color: white;
    line-height: 26px;
    margin-top: 35px;

    @media screen and (max-width: $screen-md-max) {
      margin-top: unset;
    }

    .title {
      font-size: 22px;
      font-family: 'Roboto-Bold';
    }

    .description {
      margin-top: 30px;
      font-size: 16px;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 30px;
      }
    }
  }
}
