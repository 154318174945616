@import 'assets/styles/_fonts.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --blue: #10a1f2;
  --grey: #83878f;
  --screen-sm-min: 768px;
  --screen-xs-max: (var(--screen-sm-min) - 1);
  --screen-md-min: 992px;
  --screen-sm-max: (var(--screen-md-min) - 1);
  --screen-lg-min: 1200px;
  --screen-md-max: (var(--screen-lg-min) - 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table td {
  word-break: break-word;
}
