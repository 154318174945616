@import 'assets/media_variables.scss';

.mobileOnly {
  @media screen and(min-width: $screen-md-max) {
    display: none;
  }
}

.navLink {
  text-decoration: none;
  color: white;
  margin: 0 25px;
  cursor: pointer;
  user-select: none;
  padding-bottom: 9.5px;
  text-transform: uppercase;

  &:hover {
    color: white;
    border-bottom: 2px solid white;
    transition: border-bottom 0.1s ease-in;
  }

  &.active {
    font-family: 'Roboto-Bold';
    border-bottom: 2px solid white;

    @media screen and (max-width: $screen-md-max) {
      border-bottom: unset;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    display: block;
    text-align: left;
    margin-bottom: 10px;

    &:first-of-type {
      margin-top: 175px;
    }
  }
}
