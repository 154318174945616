@import 'assets/media_variables.scss';

.continueWrapper {
  padding-top: 2rem;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: $screen-sm-max) {
    justify-content: center;
  }

  .continueContainer {
    display: flex;
    align-items: center;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    user-select: none;
    margin-bottom: 30px;

    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: unset;
    }

    .arrow {
      width: 50px;
      height: 50px;

      border-radius: 50%;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.14);

      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 10px;
    }
  }
}
