@import 'assets/media_variables.scss';

.title {
  color: white;
  font-size: 80px;
  line-height: 70px;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  cursor: default;
  user-select: none;

  @media screen and (max-width: $screen-md-max) {
    font-size: 22px;
    text-transform: capitalize;
    line-height: unset;
  }
}
