@import 'assets/media_variables.scss';

.NavigationWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: fixed;
  color: white;
  box-sizing: border-box;
  padding: 30px 30px 30px 30px;
  height: 121px;
  z-index: 3;
  background: rgba(0, 108, 144, 0.7);

  @media screen and (max-width: $screen-md-max) {
    height: 45px;
    box-sizing: unset;
    padding: 30px 15px 20px 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background: rgb(0, 108, 144);
    background: linear-gradient(
      90deg,
      rgba(0, 108, 144, 1) 90%,
      rgba(0, 87, 139, 1) 100%
    );
  }

  .container {
    max-width: 1368px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1100px) {
      width: 100%;
      padding: 0 20px;
    }

    .leftNavItems {
      @media screen and (max-width: $screen-md-max) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &.centered {
          justify-content: center;
        }
      }

      .logo {
        height: 100%;

        @media screen and (max-width: $screen-md-max) {
          max-width: 150px;
        }
      }

      .sidebarTrigger {
        @media screen and (min-width: $screen-md-max) {
          display: none;
        }
      }

      .navItems {
        padding: 0 45px;
        display: flex;
        align-items: center;
        height: 100%;
      }
    }

    .rightNavItems {
      display: flex;
      align-items: center;

      @media screen and (max-width: $screen-md-max) {
        display: none;
      }

      .navItems {
        padding: 0 45px;
        display: flex;
        align-items: center;
        height: 100%;
      }

      .item {
        margin-right: 25px;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

.sectionDropdown {
  & > {
    display: flex !important;
    background-color: orange !important;
  }

  @media screen and (min-width: $screen-md-max) {
    display: none !important;
  }
}
