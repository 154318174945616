.FlexCentered {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_error__11Dwt {
  width: 100%;
  margin-top: 20px !important; }
  .styles_error__11Dwt:first-letter {
    text-transform: uppercase; }

.styles_successMessage__1ISIf {
  margin-top: 20px !important;
  width: 100%; }

@media screen and (max-width: 767px) {
  .styles_link__1ixNr {
    word-break: break-all; } }

.styles_tab__bMkzt {
  cursor: pointer;
  padding: 15px 0px;
  text-transform: uppercase;
  color: white;
  font-family: 'Roboto-Regular';
  margin: 0 10px;
  font-size: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-flex;
  display: flex;
  line-height: 16px; }
  .styles_tab__bMkzt .styles_icon__3VXo7 {
    margin-top: -1px;
    height: 16px;
    margin-right: 5px; }
  .styles_tab__bMkzt:first-of-type {
    margin-left: 0; }
  .styles_tab__bMkzt.styles_active__2Fe-2 {
    color: white;
    font-family: 'Roboto-Bold';
    border-bottom: 2px solid white; }

.styles_wrapper__Gg3nX {
  margin-top: 20px; }
  .styles_wrapper__Gg3nX .styles_tabContainer__3I74R {
    display: -webkit-flex;
    display: flex;
    overflow: hidden; }
  .styles_wrapper__Gg3nX .styles_tabContent__w1B4c {
    margin-top: 20px; }

.styles_title__NJbnV {
  color: white;
  font-size: 80px;
  line-height: 70px;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  @media screen and (max-width: 1199px) {
    .styles_title__NJbnV {
      font-size: 22px;
      text-transform: capitalize;
      line-height: unset; } }

.styles_text__1JCGk {
  color: white;
  font-size: 16px;
  line-height: 26px; }
  .styles_text__1JCGk:first-letter {
    text-transform: uppercase; }

.styles_field__1Y5xX {
  color: white;
  display: block;
  min-width: 100%;
  border-radius: 20px;
  padding: 12px 24px;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  margin-top: 20px;
  background-color: transparent !important;
  outline: none; }
  .styles_field__1Y5xX::-webkit-input-placeholder {
    color: white; }
  .styles_field__1Y5xX::-ms-input-placeholder {
    color: white; }
  .styles_field__1Y5xX::placeholder {
    color: white; }
  .styles_field__1Y5xX:focus {
    box-shadow: 0 0 3px #ddd; }

.styles_error__27JEF {
  color: red;
  padding: 5px 10px 0 10px; }

.styles_container__75dWt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end; }
  .styles_container__75dWt .styles_tab__2voL1 {
    width: 100%;
    color: white;
    height: 34px;
    font-size: 12px;
    text-align: left;
    max-width: 149px;
    line-height: 26px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding-left: 27px;
    border-radius: 2px;
    margin-bottom: 15px;
    text-transform: uppercase; }
    .styles_container__75dWt .styles_tab__2voL1.styles_active__xyjjh, .styles_container__75dWt .styles_tab__2voL1:hover {
      cursor: pointer;
      font-family: 'Roboto-Bold';
      box-shadow: 4px 3px 4px #000000; }
  .styles_container__75dWt .styles_divider__arZv7 {
    width: 85%;
    margin-top: 10px;
    margin-bottom: 15px;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    border-bottom: 1px solid white; }

.styles_container__1t1NC {
  height: 500px;
  width: 500px;
  cursor: pointer;
  box-shadow: 0px 0px 10px black;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .styles_container__1t1NC .styles_title__2_52r {
    font-family: 'Roboto-Bold';
    text-transform: uppercase; }
  .styles_container__1t1NC .styles_chart__35Wvm {
    height: 100%;
    font-size: 5vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .styles_container__1t1NC .styles_arrow__17ZXC {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    font-size: 20px; }

.styles_continueWrapper__2TJVu {
  padding-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  @media screen and (max-width: 991px) {
    .styles_continueWrapper__2TJVu {
      -webkit-justify-content: center;
              justify-content: center; } }
  .styles_continueWrapper__2TJVu .styles_continueContainer__rNt0D {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-bottom: 30px; }
    @media screen and (max-width: 991px) {
      .styles_continueWrapper__2TJVu .styles_continueContainer__rNt0D {
        margin-bottom: unset; } }
    .styles_continueWrapper__2TJVu .styles_continueContainer__rNt0D .styles_arrow__2qbOe {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.14);
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 10px; }


.styles_container__25q3t {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 30px;
  color: white;
  font-size: 14px; }
  .styles_container__25q3t .styles_item__3CRgd {
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-align-items: center;
            align-items: center;
    text-transform: capitalize; }

.styles_wrapper__CVCRx {
  width: 100%;
  max-height: 45px;
  overflow: hidden; }
  @media screen and (max-width: 1199px) {
    .styles_wrapper__CVCRx.styles_desktop__VcUgk {
      display: none; } }
  @media screen and (min-width: 1199px) {
    .styles_wrapper__CVCRx.styles_mobile__3d1hG {
      display: none; } }
  .styles_wrapper__CVCRx.styles_active__13-1a {
    -webkit-order: 0;
            order: 0;
    overflow: unset;
    padding-bottom: 100px;
    -webkit-animation: styles_slideUp__cyt5i 0.5s;
            animation: styles_slideUp__cyt5i 0.5s; }
    .styles_wrapper__CVCRx.styles_active__13-1a .styles_barContainer__l1Ss- {
      background-color: rgba(245, 247, 251, 0.5); }
  .styles_wrapper__CVCRx.styles_disabled__1GBVj {
    -webkit-order: 2;
            order: 2;
    opacity: 0.3; }
  .styles_wrapper__CVCRx .styles_barContainer__l1Ss- {
    cursor: pointer;
    width: 100%;
    height: 45px;
    background-color: rgba(245, 247, 251, 0.6);
    border-radius: 300px;
    display: -webkit-flex;
    display: flex; }
    .styles_wrapper__CVCRx .styles_barContainer__l1Ss- .styles_progress__2lKu7 {
      height: 99%;
      display: -webkit-flex;
      display: flex;
      min-width: 20px;
      border-radius: 300px;
      box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.16);
      -webkit-animation: styles_spread__2KK6n 2s;
              animation: styles_spread__2KK6n 2s; }
      .styles_wrapper__CVCRx .styles_barContainer__l1Ss- .styles_progress__2lKu7.styles_blue__RmDFc {
        background-color: #00dafb; }
      .styles_wrapper__CVCRx .styles_barContainer__l1Ss- .styles_progress__2lKu7.styles_green__3NWK2 {
        background-color: #14bf63; }
      .styles_wrapper__CVCRx .styles_barContainer__l1Ss- .styles_progress__2lKu7.styles_pink__2RgiL {
        background-color: #e454a1; }
      .styles_wrapper__CVCRx .styles_barContainer__l1Ss- .styles_progress__2lKu7.styles_orange__ZTNnI {
        background-color: #f9b233; }
      .styles_wrapper__CVCRx .styles_barContainer__l1Ss- .styles_progress__2lKu7.styles_yellow__EYtfb {
        background-color: #f9c84b; }
      .styles_wrapper__CVCRx .styles_barContainer__l1Ss- .styles_progress__2lKu7 .styles_text__Fc6sv {
        margin-left: 30px;
        line-height: 45px;
        text-transform: uppercase;
        -webkit-animation: styles_slideRight__2qocb 2s;
                animation: styles_slideRight__2qocb 2s;
        color: white;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        position: absolute; }
  .styles_wrapper__CVCRx .styles_textContainer__P5Wnf {
    display: -webkit-flex;
    display: flex;
    margin-top: 8.5px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color: white;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

@-webkit-keyframes styles_slideRight__2qocb {
  0% {
    opacity: 0;
    margin-left: -75px; } }

@keyframes styles_slideRight__2qocb {
  0% {
    opacity: 0;
    margin-left: -75px; } }

@-webkit-keyframes styles_spread__2KK6n {
  0% {
    width: 0; } }

@keyframes styles_spread__2KK6n {
  0% {
    width: 0; } }

@-webkit-keyframes styles_slideUp__cyt5i {
  0% {
    opacity: 0.3;
    padding-bottom: 0; } }

@keyframes styles_slideUp__cyt5i {
  0% {
    opacity: 0.3;
    padding-bottom: 0; } }

.styles_progressBarContainer__2oCFI {
  position: fixed;
  bottom: 0;
  z-index: 20;
  max-width: 520px;
  width: calc(100% - 130px); }

.styles_botProgressBar__ElQ83 {
  height: 28px !important; }

.styles_progress__19ctY {
  z-index: 100;
  height: 30px;
  margin-bottom: 8px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
  .styles_progress__19ctY .styles_active__1ubiY {
    -webkit-animation: styles_progress-bar-stripes__DGWnE 2s linear infinite;
    animation: styles_progress-bar-stripes__DGWnE 2s linear infinite; }
  .styles_progress__19ctY .styles_progressBar__2l8KO {
    float: left;
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease; }
  .styles_progress__19ctY .styles_progressBarStriped__2FcC8 {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px; }
  .styles_progress__19ctY .styles_progressBarWarning__oys4_ {
    background-color: #f0ad4e; }

@-webkit-keyframes styles_progress-bar-stripes__DGWnE {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@keyframes styles_progress-bar-stripes__DGWnE {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

.styles_container__1_bHD {
  max-height: 60px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  width: 550px; }
  @media screen and (max-width: 1199px) {
    .styles_container__1_bHD.styles_desktop__24_Oe {
      display: none; } }
  @media screen and (min-width: 1199px) {
    .styles_container__1_bHD.styles_mobile__32HHT {
      display: none; } }
  .styles_container__1_bHD.styles_active__1QlGP {
    overflow: unset;
    padding-bottom: 50px; }
  .styles_container__1_bHD.styles_disabled__2cCGw {
    opacity: 0.3; }
  .styles_container__1_bHD .styles_markerContainer__2md5_ {
    width: 495px;
    height: 0; }
    .styles_container__1_bHD .styles_markerContainer__2md5_ .styles_emptyContainer__3GTRM {
      width: 35px; }
    .styles_container__1_bHD .styles_markerContainer__2md5_ .styles_marker__-89KV {
      width: 0;
      height: 0;
      position: relative;
      -webkit-animation: styles_slideFromLeft__17cvE 1s;
              animation: styles_slideFromLeft__17cvE 1s;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-top: 30px solid #14bf63;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent; }
      .styles_container__1_bHD .styles_markerContainer__2md5_ .styles_marker__-89KV.styles_blue__2MeaQ {
        border-top-color: #00dafb; }
      .styles_container__1_bHD .styles_markerContainer__2md5_ .styles_marker__-89KV.styles_green__1IfNh {
        border-top-color: #14bf63; }
      .styles_container__1_bHD .styles_markerContainer__2md5_ .styles_marker__-89KV.styles_pink__2Q7vT {
        border-top-color: #e454a1; }
      .styles_container__1_bHD .styles_markerContainer__2md5_ .styles_marker__-89KV.styles_orange__jUUTR {
        border-top-color: #f9b233; }
  .styles_container__1_bHD .styles_labels__10nEd {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    position: absolute; }
  .styles_container__1_bHD .styles_leftText__1O95o,
  .styles_container__1_bHD .styles_rightText__3GuPs {
    color: white;
    font-size: 12px;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    overflow: visible; }

@-webkit-keyframes styles_slideFromLeft__17cvE {
  0% {
    margin-bottom: 10px;
    border-color: transparent;
    border-top-color: white;
    left: 0%; } }

@keyframes styles_slideFromLeft__17cvE {
  0% {
    margin-bottom: 10px;
    border-color: transparent;
    border-top-color: white;
    left: 0%; } }

.styles_Page__3RUrJ {
  width: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-position: center;
  background-size: cover;
  height: 100%;
  background-attachment: fixed;
  background-image: url(/static/media/background.67e051c1.png); }
  .styles_Page__3RUrJ:before {
    content: '';
    height: 110vh;
    position: fixed;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1; }
    @media screen and (max-width: 1199px) {
      .styles_Page__3RUrJ:before {
        background-color: rgba(0, 0, 0, 0.4); } }

.styles_PageBody__2exgU {
  padding-top: 120px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100%;
  z-index: 2; }
  @media screen and (max-width: 1199px) {
    .styles_PageBody__2exgU {
      padding-top: 50px; } }

.styles_PageContent__2LIM6 {
  width: 100%;
  z-index: 2;
  max-width: 1428px;
  padding: 0 30px;
  box-sizing: border-box; }
  @media screen and (max-width: 1100px) {
    .styles_PageContent__2LIM6 {
      width: 100%;
      padding: 35px 50px; } }
  @media screen and (max-width: 767px) {
    .styles_PageContent__2LIM6 {
      padding: 35px 25px; } }

.styles_container__3dONR {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (max-width: 1199px) {
    .styles_container__3dONR {
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse;
      -webkit-justify-content: center;
              justify-content: center; } }
  .styles_container__3dONR .styles_left__179jA {
    -webkit-flex-basis: 50%;
            flex-basis: 50%; }
    .styles_container__3dONR .styles_left__179jA .styles_title__3eepE {
      margin-top: 65px;
      margin-bottom: 45px; }
      @media screen and (max-width: 1199px) {
        .styles_container__3dONR .styles_left__179jA .styles_title__3eepE {
          margin-top: 15px; } }
    .styles_container__3dONR .styles_left__179jA .styles_description__3kKS0 {
      max-width: 460px;
      white-space: pre-wrap; }
      @media screen and (max-width: 1199px) {
        .styles_container__3dONR .styles_left__179jA .styles_description__3kKS0 {
          max-width: unset; } }
  .styles_container__3dONR .styles_right__2UAGu {
    -webkit-flex-basis: 50%;
            flex-basis: 50%; }
    @media screen and (max-width: 1199px) {
      .styles_container__3dONR .styles_right__2UAGu .styles_chart__2SSyr {
        margin-top: -25px;
        margin-bottom: -50px; } }
    @media screen and (max-width: 767px) {
      .styles_container__3dONR .styles_right__2UAGu .styles_chart__2SSyr {
        margin-bottom: -90px; } }

.styles_container__uDXhe {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 65px; }
  @media screen and (max-width: 1199px) {
    .styles_container__uDXhe {
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse;
      padding-top: unset; } }
  .styles_container__uDXhe .styles_left__3mHam {
    -webkit-flex-basis: 30%;
            flex-basis: 30%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .styles_container__uDXhe .styles_left__3mHam > .styles_title__2orXP {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 60px;
      white-space: nowrap; }
      @media screen and (max-width: 1199px) {
        .styles_container__uDXhe .styles_left__3mHam > .styles_title__2orXP {
          display: none; } }
      .styles_container__uDXhe .styles_left__3mHam > .styles_title__2orXP .styles_titleIcon__2Q6Ds {
        height: 60px;
        margin-right: 10px; }
    @media screen and (min-width: 1199px) {
      .styles_container__uDXhe .styles_left__3mHam .styles_progressBarAdditional__2b3Y7 {
        display: none; } }
    .styles_container__uDXhe .styles_left__3mHam .styles_progressBarAdditional__2b3Y7 .styles_title__2orXP {
      margin-bottom: 20px; }
    .styles_container__uDXhe .styles_left__3mHam .styles_progressBar__1rDdW {
      margin-bottom: 10px; }
      @media screen and (max-width: 1199px) {
        .styles_container__uDXhe .styles_left__3mHam .styles_progressBar__1rDdW {
          margin-top: 25px;
          margin-bottom: 60px; } }
  .styles_container__uDXhe .styles_right__7lKw8 {
    -webkit-flex-basis: 45%;
            flex-basis: 45%;
    color: white;
    line-height: 26px;
    margin-top: 35px; }
    @media screen and (max-width: 1199px) {
      .styles_container__uDXhe .styles_right__7lKw8 {
        margin-top: unset; } }
    .styles_container__uDXhe .styles_right__7lKw8 .styles_title__2orXP {
      font-size: 22px;
      font-family: 'Roboto-Bold'; }
    .styles_container__uDXhe .styles_right__7lKw8 .styles_description__262cW {
      margin-top: 30px;
      font-size: 16px; }
      @media screen and (max-width: 1199px) {
        .styles_container__uDXhe .styles_right__7lKw8 .styles_description__262cW {
          margin-bottom: 30px; } }

.styles_container__2-EcL {
  padding-top: 65px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (max-width: 1199px) {
    .styles_container__2-EcL {
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-top: unset; } }
  .styles_container__2-EcL .styles_left__t-rdT {
    -webkit-flex-basis: 45%;
            flex-basis: 45%; }
    .styles_container__2-EcL .styles_left__t-rdT .styles_titleContainer__1pXXw {
      margin-bottom: 45px;
      white-space: nowrap; }
      @media screen and (max-width: 1199px) {
        .styles_container__2-EcL .styles_left__t-rdT .styles_titleContainer__1pXXw {
          margin-bottom: 20px; } }
      .styles_container__2-EcL .styles_left__t-rdT .styles_titleContainer__1pXXw .styles_icon__1N-fH {
        height: 60px;
        margin-right: 10px; }
        @media screen and (max-width: 1199px) {
          .styles_container__2-EcL .styles_left__t-rdT .styles_titleContainer__1pXXw .styles_icon__1N-fH {
            display: none; } }
    .styles_container__2-EcL .styles_left__t-rdT .styles_sectionTitle__1nUhi {
      color: white;
      margin-bottom: 45px;
      font-family: 'Roboto-Bold';
      font-size: 22px;
      line-height: 26px; }
      @media screen and (max-width: 1199px) {
        .styles_container__2-EcL .styles_left__t-rdT .styles_sectionTitle__1nUhi {
          display: none; } }
    @media screen and (max-width: 1199px) {
      .styles_container__2-EcL .styles_left__t-rdT .styles_sectionDescription__3A62d {
        margin: 30px 0 60px 0; } }
    .styles_container__2-EcL .styles_left__t-rdT .styles_navigation__3_wHW {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      padding-top: 30px;
      color: white;
      font-size: 14px; }
      .styles_container__2-EcL .styles_left__t-rdT .styles_navigation__3_wHW .styles_item__3qSke {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        cursor: pointer; }
  .styles_container__2-EcL .styles_right__2vGOq {
    -webkit-flex-basis: 45%;
            flex-basis: 45%; }
    @media screen and (max-width: 1199px) {
      .styles_container__2-EcL .styles_right__2vGOq {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center; } }
    @media screen and (min-width: 1199px) {
      .styles_container__2-EcL .styles_right__2vGOq .styles_chartAdditional__3QFng {
        display: none; } }
    .styles_container__2-EcL .styles_right__2vGOq .styles_chartAdditional__3QFng .styles_description__2IFll {
      margin: 20px 0; }
    .styles_container__2-EcL .styles_right__2vGOq .styles_item__3qSke {
      margin-top: 1rem;
      max-width: 400px; }
      @media screen and (max-width: 1199px) {
        .styles_container__2-EcL .styles_right__2vGOq .styles_item__3qSke {
          margin-bottom: 55px;
          width: 100%;
          max-width: unset; } }
    .styles_container__2-EcL .styles_right__2vGOq .styles_lineChartItem__vD1GL {
      margin-top: 2rem;
      cursor: pointer; }
      @media screen and (max-width: 1199px) {
        .styles_container__2-EcL .styles_right__2vGOq .styles_lineChartItem__vD1GL {
          margin-bottom: 55px; } }
      @media screen and (max-width: 767px) {
        .styles_container__2-EcL .styles_right__2vGOq .styles_lineChartItem__vD1GL {
          -webkit-transform: scale(0.6);
                  transform: scale(0.6); } }

.styles_container__3mm8T {
  padding-top: 65px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (max-width: 1199px) {
    .styles_container__3mm8T {
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-top: unset; } }
  .styles_container__3mm8T .styles_left__mt0G6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%; }
    .styles_container__3mm8T .styles_left__mt0G6 .styles_title__2jIXh {
      white-space: nowrap;
      margin-bottom: 45px; }
      @media screen and (max-width: 1199px) {
        .styles_container__3mm8T .styles_left__mt0G6 .styles_title__2jIXh {
          margin-bottom: 20px; } }
      .styles_container__3mm8T .styles_left__mt0G6 .styles_title__2jIXh .styles_icon__1nhU2 {
        height: 60px;
        margin-right: 10px; }
        @media screen and (max-width: 1199px) {
          .styles_container__3mm8T .styles_left__mt0G6 .styles_title__2jIXh .styles_icon__1nhU2 {
            display: none; } }
    .styles_container__3mm8T .styles_left__mt0G6 .styles_subTitle__edK-R {
      color: white;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 25px;
      font-family: 'Roboto-Bold';
      text-transform: capitalize; }
  .styles_container__3mm8T .styles_right__Zf3x4 {
    width: 100%;
    max-width: 400px;
    padding-top: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    @media screen and (max-width: 1199px) {
      .styles_container__3mm8T .styles_right__Zf3x4 {
        padding-top: 5px;
        max-width: unset; } }
    @media screen and (min-width: 1199px) {
      .styles_container__3mm8T .styles_right__Zf3x4 .styles_progressBarAdditional__5cVH5 {
        display: none; } }
    .styles_container__3mm8T .styles_right__Zf3x4 .styles_progressBarAdditional__5cVH5 .styles_title__2jIXh {
      margin-top: 60px;
      margin-bottom: 20px;
      text-transform: capitalize; }
    .styles_container__3mm8T .styles_right__Zf3x4 .styles_progressBar__2ynkO {
      margin-bottom: 10px; }
      @media screen and (max-width: 1199px) {
        .styles_container__3mm8T .styles_right__Zf3x4 .styles_progressBar__2ynkO {
          margin-top: 20px; } }

.styles_container__3Vuwz {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (max-width: 1199px) {
    .styles_container__3Vuwz {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .styles_container__3Vuwz .styles_left__zM53V,
  .styles_container__3Vuwz .styles_right__T6QU5 {
    -webkit-flex-basis: 45%;
            flex-basis: 45%; }
    .styles_container__3Vuwz .styles_left__zM53V .styles_title__tT5xV,
    .styles_container__3Vuwz .styles_right__T6QU5 .styles_title__tT5xV {
      word-spacing: 1000px;
      margin-bottom: 30px; }
      @media screen and (max-width: 1199px) {
        .styles_container__3Vuwz .styles_left__zM53V .styles_title__tT5xV,
        .styles_container__3Vuwz .styles_right__T6QU5 .styles_title__tT5xV {
          font-size: 48px;
          line-height: 45px;
          text-transform: uppercase;
          margin-bottom: 45px; } }
    .styles_container__3Vuwz .styles_left__zM53V .styles_boxTitle__3f47s,
    .styles_container__3Vuwz .styles_right__T6QU5 .styles_boxTitle__3f47s {
      text-transform: uppercase;
      line-height: 26px;
      color: #f9b233;
      font-family: 'Roboto-Bold'; }
    .styles_container__3Vuwz .styles_left__zM53V .styles_boxContent___MYxK,
    .styles_container__3Vuwz .styles_right__T6QU5 .styles_boxContent___MYxK {
      margin-top: 16px;
      margin-bottom: 30px; }
      @media screen and (max-width: 1199px) {
        .styles_container__3Vuwz .styles_left__zM53V .styles_boxContent___MYxK,
        .styles_container__3Vuwz .styles_right__T6QU5 .styles_boxContent___MYxK {
          margin-top: 25px;
          margin-bottom: 46px; } }

.styles_container__23gGe .styles_title__3nG8x {
  margin-top: 35px; }
  @media screen and (max-width: 1199px) {
    .styles_container__23gGe .styles_title__3nG8x {
      margin-top: unset;
      font-size: 48px;
      text-transform: uppercase;
      line-height: 45px; } }

.styles_container__23gGe .styles_text__3hAUA {
  margin-top: 65px;
  max-width: 50%; }
  @media screen and (max-width: 1199px) {
    .styles_container__23gGe .styles_text__3hAUA {
      max-width: 100%; } }
  @media screen and (max-width: 1199px) {
    .styles_container__23gGe .styles_text__3hAUA img {
      max-width: 100%; } }

.styles_NavigationWrapper__TOvzJ {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 1;
  position: fixed;
  color: white;
  box-sizing: border-box;
  padding: 30px 30px 30px 30px;
  height: 121px;
  z-index: 3;
  background: rgba(0, 108, 144, 0.7); }
  @media screen and (max-width: 1199px) {
    .styles_NavigationWrapper__TOvzJ {
      height: 45px;
      box-sizing: unset;
      padding: 30px 15px 20px 15px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background: #006c90;
      background: -webkit-linear-gradient(left, #006c90 90%, #00578b 100%);
      background: linear-gradient(90deg, #006c90 90%, #00578b 100%); } }
  .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 {
    max-width: 1368px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    @media screen and (max-width: 1100px) {
      .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 {
        width: 100%;
        padding: 0 20px; } }
    @media screen and (max-width: 1199px) {
      .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_leftNavItems__2b-2a {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width: 100%; }
        .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_leftNavItems__2b-2a.styles_centered__1CRJj {
          -webkit-justify-content: center;
                  justify-content: center; } }
    .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_leftNavItems__2b-2a .styles_logo__1sTh- {
      height: 100%; }
      @media screen and (max-width: 1199px) {
        .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_leftNavItems__2b-2a .styles_logo__1sTh- {
          max-width: 150px; } }
    @media screen and (min-width: 1199px) {
      .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_leftNavItems__2b-2a .styles_sidebarTrigger__3qouO {
        display: none; } }
    .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_leftNavItems__2b-2a .styles_navItems__3Kv1y {
      padding: 0 45px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      height: 100%; }
    .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_rightNavItems__15Ot7 {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      @media screen and (max-width: 1199px) {
        .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_rightNavItems__15Ot7 {
          display: none; } }
      .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_rightNavItems__15Ot7 .styles_navItems__3Kv1y {
        padding: 0 45px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        height: 100%; }
      .styles_NavigationWrapper__TOvzJ .styles_container__1Okb1 .styles_rightNavItems__15Ot7 .styles_item__1TIjM {
        margin-right: 25px;
        cursor: pointer;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none; }

.styles_sectionDropdown__3x3sE > {
  display: -webkit-flex !important;
  display: flex !important;
  background-color: orange !important; }

@media screen and (min-width: 1199px) {
  .styles_sectionDropdown__3x3sE {
    display: none !important; } }

.styles_Page__ORjiK {
  background-color: #222222; }

.styles_PageBody__G_fsP {
  min-height: 100vh;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 20px;
  box-sizing: border-box; }

.styles_PageContent__8Izil {
  max-width: 1300px;
  padding-top: 270px;
  padding-right: 65px;
  box-sizing: border-box;
  width: 100%; }
  @media screen and (max-width: 1650px) {
    .styles_PageContent__8Izil {
      padding-top: 230px;
      padding-left: 210px; } }
  @media screen and (max-width: 1199px) {
    .styles_PageContent__8Izil {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 150px 25px 25px 25px;
      -webkit-flex-direction: column;
              flex-direction: column;
      min-width: 100vw;
      max-width: 100vw; } }
  @media screen and (max-width: 767px) {
    .styles_PageContent__8Izil {
      padding: 150px 10px 25px 10px; } }

@media screen and (min-width: 1199px) {
  .styles_mobileOnly__3JG-4 {
    display: none; } }

.styles_navLink__2dtn- {
  text-decoration: none;
  color: white;
  margin: 0 25px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 9.5px;
  text-transform: uppercase; }
  .styles_navLink__2dtn-:hover {
    color: white;
    border-bottom: 2px solid white;
    -webkit-transition: border-bottom 0.1s ease-in;
    transition: border-bottom 0.1s ease-in; }
  .styles_navLink__2dtn-.styles_active__a-QIx {
    font-family: 'Roboto-Bold';
    border-bottom: 2px solid white; }
    @media screen and (max-width: 1199px) {
      .styles_navLink__2dtn-.styles_active__a-QIx {
        border-bottom: unset; } }
  @media screen and (max-width: 1199px) {
    .styles_navLink__2dtn- {
      display: block;
      text-align: left;
      margin-bottom: 10px; }
      .styles_navLink__2dtn-:first-of-type {
        margin-top: 175px; } }

.styles_wrapper__29Ryy {
  z-index: 4;
  width: 100%;
  color: white;
  height: 122px;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  padding: 40px 0;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #191919;
  box-shadow: 0px 10px 10px black; }
  @media screen and (max-width: 1199px) {
    .styles_wrapper__29Ryy {
      height: 125px;
      padding: 36px 25px 18px 25px; } }
  @media screen and (max-width: 767px) {
    .styles_wrapper__29Ryy {
      height: 125px;
      padding: 36px 0 18px 0; } }
  .styles_wrapper__29Ryy .styles_container__2QL0W {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding: 0 160px;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    @media screen and (max-width: 1199px) {
      .styles_wrapper__29Ryy .styles_container__2QL0W {
        padding: 0 20px;
        -webkit-flex-direction: column;
                flex-direction: column; } }
    .styles_wrapper__29Ryy .styles_container__2QL0W .styles_left__2A4BI {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      width: 100%; }
      @media screen and (max-width: 1199px) {
        .styles_wrapper__29Ryy .styles_container__2QL0W .styles_left__2A4BI > * {
          -webkit-flex-basis: 33.33%;
                  flex-basis: 33.33%; } }
      .styles_wrapper__29Ryy .styles_container__2QL0W .styles_left__2A4BI .styles_logo__2406p {
        height: 100%;
        cursor: pointer;
        margin-right: 60px;
        max-height: 42.55px; }
        @media screen and (max-width: 1199px) {
          .styles_wrapper__29Ryy .styles_container__2QL0W .styles_left__2A4BI .styles_logo__2406p {
            margin-right: unset; } }
      @media screen and (min-width: 1199px) {
        .styles_wrapper__29Ryy .styles_container__2QL0W .styles_left__2A4BI .styles_dropdown__393z1 {
          display: none; } }
      @media screen and (max-width: 1199px) {
        .styles_wrapper__29Ryy .styles_container__2QL0W .styles_left__2A4BI .styles_dropdown__393z1 {
          text-align: right; } }
      @media screen and (max-width: 767px) {
        .styles_wrapper__29Ryy .styles_container__2QL0W .styles_left__2A4BI .styles_dropdown__393z1 {
          font-size: 11px; } }
    .styles_wrapper__29Ryy .styles_container__2QL0W .styles_mobileIcon__1D9R3 {
      display: none; }
      @media screen and (max-width: 1199px) {
        .styles_wrapper__29Ryy .styles_container__2QL0W .styles_mobileIcon__1D9R3 {
          display: block; }
          .styles_wrapper__29Ryy .styles_container__2QL0W .styles_mobileIcon__1D9R3.styles_active__BzqMd {
            z-index: -1; } }
      .styles_wrapper__29Ryy .styles_container__2QL0W .styles_mobileIcon__1D9R3 .styles_icon__35aSz {
        content: 'menu';
        font-family: 'Material Icons'; }
    @media screen and (max-width: 1199px) {
      .styles_wrapper__29Ryy .styles_container__2QL0W .styles_navLinks__1rsIb {
        display: none; } }
    .styles_wrapper__29Ryy .styles_container__2QL0W .styles_right__3rlGg {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      white-space: nowrap; }
      .styles_wrapper__29Ryy .styles_container__2QL0W .styles_right__3rlGg .styles_greet__1yci1 {
        font-family: 'Roboto-Bold';
        width: 100%;
        text-align: right; }
        @media screen and (max-width: 1199px) {
          .styles_wrapper__29Ryy .styles_container__2QL0W .styles_right__3rlGg .styles_greet__1yci1 {
            display: none; } }
      @media screen and (max-width: 1199px) {
        .styles_wrapper__29Ryy .styles_container__2QL0W .styles_right__3rlGg .styles_desktopDropdown__27Cj0 {
          display: none; } }
      .styles_wrapper__29Ryy .styles_container__2QL0W .styles_right__3rlGg .styles_additional__1Zhxb {
        color: #f9b233;
        font-size: 12px;
        line-height: 26px;
        width: 100%;
        text-align: right; }
        @media screen and (max-width: 1199px) {
          .styles_wrapper__29Ryy .styles_container__2QL0W .styles_right__3rlGg .styles_additional__1Zhxb {
            text-align: center; } }

.styles_sidebar__2s232 {
  z-index: 2 !important; }

.styles_boxWrapper__2XAtt {
  padding: 30px 23px;
  box-shadow: 10px 10px 3px 0px rgba(0, 0, 0, 0.26);
  background-color: #1c1c1c;
  border-radius: 6px; }
  .styles_boxWrapper__2XAtt .styles_title__1rPVH {
    color: #f9b233;
    font-size: 22px;
    line-height: 18px;
    font-family: 'Roboto-Bold';
    padding-bottom: 18px; }
  .styles_boxWrapper__2XAtt .styles_content__31UTs {
    color: white;
    line-height: 26px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    @media screen and (max-width: 1440px) {
      .styles_boxWrapper__2XAtt .styles_content__31UTs {
        max-width: 450px; } }
    @media screen and (max-width: 1199px) {
      .styles_boxWrapper__2XAtt .styles_content__31UTs {
        max-width: unset; } }

.styles_container__16FjO {
  width: 100%; }
  .styles_container__16FjO .styles_form__37VXu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20px; }
    @media screen and (max-width: 1199px) {
      .styles_container__16FjO .styles_form__37VXu {
        padding: 0 10px; } }
    .styles_container__16FjO .styles_form__37VXu .styles_submit__h-Vfj {
      text-transform: uppercase;
      height: 40px;
      width: 100%;
      border-radius: 20px;
      border: 0px;
      background-color: #f9b233;
      font-size: 16px;
      color: white;
      margin-top: 20px;
      cursor: pointer; }
      .styles_container__16FjO .styles_form__37VXu .styles_submit__h-Vfj:disabled {
        cursor: not-allowed;
        -webkit-filter: brightness(0.7);
                filter: brightness(0.7); }

.styles_container__3S6Kg {
  width: 100%; }
  .styles_container__3S6Kg .styles_form__3UBtF {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    max-width: 380px;
    width: 100%; }
    @media screen and (max-width: 1199px) {
      .styles_container__3S6Kg .styles_form__3UBtF {
        padding: 0 10px; } }
    .styles_container__3S6Kg .styles_form__3UBtF .styles_submit__CXsTd {
      text-transform: uppercase;
      height: 40px;
      width: 100%;
      border-radius: 20px;
      border: 0px;
      background-color: #f9b233;
      font-size: 16px;
      color: white;
      margin-top: 20px; }
      .styles_container__3S6Kg .styles_form__3UBtF .styles_submit__CXsTd:disabled {
        cursor: not-allowed;
        -webkit-filter: brightness(0.7);
                filter: brightness(0.7); }

.styles_wrapper__3h_6c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100vh;
  margin-top: -50px; }
  .styles_wrapper__3h_6c .styles_formWrapper__Yg7Sl {
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    max-width: 380px;
    width: 100%; }
    .styles_wrapper__3h_6c .styles_formWrapper__Yg7Sl .styles_logo__3N12R {
      max-width: 250px; }
    .styles_wrapper__3h_6c .styles_formWrapper__Yg7Sl .styles_title__33Ds1 {
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      padding-bottom: 30px; }
    .styles_wrapper__3h_6c .styles_formWrapper__Yg7Sl .styles_subLink__2yXCM {
      margin-top: 20px;
      cursor: pointer;
      font-size: 14px;
      color: white;
      text-decoration: underline; }

.styles_checkbox__29SYn > label {
  margin-top: 20px;
  color: white !important;
  font-size: 14px;
  font-family: 'Roboto-Regular'; }
  .styles_checkbox__29SYn > label .styles_link__2m5eb {
    font-family: 'Roboto-Bold';
    color: #f9b233; }

.styles_checkboxErrors__xwgoY {
  color: red;
  padding: 5px 10px 0 10px; }

.styles_container__3h5AM {
  min-height: 100vh;
  padding-top: 150px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  z-index: 2;
  width: 100%;
  max-width: 380px; }
  @media screen and (max-width: 1199px) {
    .styles_container__3h5AM {
      padding: 130px 10px 50px 10px; } }
  .styles_container__3h5AM .styles_logo___R65V {
    max-width: 250px; }
  .styles_container__3h5AM .styles_form__121iU {
    max-width: 380px;
    width: 100%; }
    .styles_container__3h5AM .styles_form__121iU .styles_select__269AQ {
      display: block;
      border-radius: 20px !important;
      font-size: 15px;
      width: 100%;
      margin-top: 20px;
      outline: none;
      color: white !important;
      background-color: transparent !important;
      border: 1px solid #f4f4f4 !important; }
      .styles_container__3h5AM .styles_form__121iU .styles_select__269AQ > div,
      .styles_container__3h5AM .styles_form__121iU .styles_select__269AQ > input {
        color: white !important; }
      .styles_container__3h5AM .styles_form__121iU .styles_select__269AQ input {
        background-color: transparent !important; }
      .styles_container__3h5AM .styles_form__121iU .styles_select__269AQ > div {
        color: white !important; }
    .styles_container__3h5AM .styles_form__121iU .styles_submit__2JSbP {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 0px;
      background-color: #f9b233;
      font-size: 17px;
      font-weight: bold;
      color: white;
      cursor: pointer;
      margin-top: 20px; }
      .styles_container__3h5AM .styles_form__121iU .styles_submit__2JSbP:disabled {
        cursor: not-allowed;
        -webkit-filter: brightness(0.7);
                filter: brightness(0.7); }
  .styles_container__3h5AM .styles_cancel__3jnju {
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    font-size: 14px;
    color: white; }

.styles_container__1IYxV {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

.styles_title__39Ko7 {
  color: white;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  padding-bottom: 20px;
  z-index: 2; }

.styles_form__3_kuJ {
  z-index: 2;
  max-width: 380px; }
  .styles_form__3_kuJ .styles_field__3VSgT {
    width: 100%;
    color: white;
    display: block;
    border-radius: 20px;
    padding: 12px 24px;
    border: 1px solid #f4f4f4;
    font-size: 16px;
    margin-bottom: 20px;
    background-color: transparent !important;
    outline: none; }
    .styles_form__3_kuJ .styles_field__3VSgT::-webkit-input-placeholder {
      color: white; }
    .styles_form__3_kuJ .styles_field__3VSgT::-ms-input-placeholder {
      color: white; }
    .styles_form__3_kuJ .styles_field__3VSgT::placeholder {
      color: white; }
    .styles_form__3_kuJ .styles_field__3VSgT:focus {
      box-shadow: 0 0 3px #ddd; }
  .styles_form__3_kuJ .styles_submit__1jefs {
    margin-top: 20px;
    text-transform: uppercase;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 0px;
    background-color: #f9b233;
    font-size: 16px;
    color: white;
    cursor: pointer; }
  .styles_form__3_kuJ .styles_cancel__d7EbD {
    margin-top: 10px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    text-decoration: underline;
    text-align: center; }

@media screen and (max-width: 1199px) {
  .styles_tabs__2YZJw {
    display: none !important; } }

.styles_container__3MaIq {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (max-width: 1199px) {
    .styles_container__3MaIq {
      -webkit-flex-direction: column;
              flex-direction: column;
      width: 100%; } }
  .styles_container__3MaIq .styles_left__1oaF8 {
    width: 45%; }
    @media screen and (max-width: 1199px) {
      .styles_container__3MaIq .styles_left__1oaF8 {
        width: 100%;
        margin-bottom: 20px; } }
    .styles_container__3MaIq .styles_left__1oaF8 .styles_aqText__3ppgH {
      width: 100%;
      white-space: pre-wrap; }
    .styles_container__3MaIq .styles_left__1oaF8 .styles_chart__37JAj {
      -webkit-transform: scale(1.3);
              transform: scale(1.3); }
      @media screen and (max-width: 1199px) {
        .styles_container__3MaIq .styles_left__1oaF8 .styles_chart__37JAj {
          -webkit-transform: unset;
                  transform: unset; } }
      @media screen and (max-width: 767px) {
        .styles_container__3MaIq .styles_left__1oaF8 .styles_chart__37JAj {
          margin-bottom: -75px; } }
  .styles_container__3MaIq .styles_right__nFWmz {
    width: 45%; }
    @media screen and (max-width: 1199px) {
      .styles_container__3MaIq .styles_right__nFWmz {
        width: 100%; } }
    .styles_container__3MaIq .styles_right__nFWmz .styles_item__1zHFt {
      margin-bottom: 15px; }
      @media screen and (max-width: 1199px) {
        .styles_container__3MaIq .styles_right__nFWmz .styles_item__1zHFt {
          margin-bottom: 20px; } }
      .styles_container__3MaIq .styles_right__nFWmz .styles_item__1zHFt .styles_progressBar__hVeKU {
        margin-top: 35px; }

.styles_container__1mgCF {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: white; }
  @media screen and (max-width: 1199px) {
    .styles_container__1mgCF {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .styles_container__1mgCF .styles_left__Jyn9S {
    width: 45%; }
    @media screen and (max-width: 1199px) {
      .styles_container__1mgCF .styles_left__Jyn9S {
        width: 100%;
        margin-bottom: 20px; } }
  .styles_container__1mgCF .styles_right__2tcI2 {
    width: 45%; }
    @media screen and (max-width: 1199px) {
      .styles_container__1mgCF .styles_right__2tcI2 {
        width: 100%; } }
    .styles_container__1mgCF .styles_right__2tcI2 .styles_abilityItem__3zkie {
      margin-bottom: 15px; }
      @media screen and (max-width: 1199px) {
        .styles_container__1mgCF .styles_right__2tcI2 .styles_abilityItem__3zkie {
          margin-bottom: 20px; } }
      .styles_container__1mgCF .styles_right__2tcI2 .styles_abilityItem__3zkie .styles_progressBar__1gZQf {
        margin-top: 35px; }

.styles_container__1hUpJ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (max-width: 1199px) {
    .styles_container__1hUpJ {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .styles_container__1hUpJ .styles_left__-wiJ6,
  .styles_container__1hUpJ .styles_right__3pUvY {
    width: 45%; }
    @media screen and (max-width: 1199px) {
      .styles_container__1hUpJ .styles_left__-wiJ6,
      .styles_container__1hUpJ .styles_right__3pUvY {
        width: unset;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
                flex-direction: column; } }
  .styles_container__1hUpJ .styles_item__DHJtH {
    margin-bottom: 15px; }
    @media screen and (max-width: 1199px) {
      .styles_container__1hUpJ .styles_item__DHJtH {
        margin-bottom: 20px; } }
    .styles_container__1hUpJ .styles_item__DHJtH .styles_progressBar__1FXon {
      margin-top: 35px; }
    .styles_container__1hUpJ .styles_item__DHJtH .styles_lineChart__5MTBf {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 500px;
      -webkit-transform: scale(0.75);
              transform: scale(0.75); }
      @media screen and (max-width: 1199px) {
        .styles_container__1hUpJ .styles_item__DHJtH .styles_lineChart__5MTBf {
          -webkit-transform: scale(1.3);
                  transform: scale(1.3);
          width: unset; } }
      @media screen and (max-width: 991px) {
        .styles_container__1hUpJ .styles_item__DHJtH .styles_lineChart__5MTBf {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
          margin-top: 2rem; } }
      @media screen and (max-width: 767px) {
        .styles_container__1hUpJ .styles_item__DHJtH .styles_lineChart__5MTBf {
          -webkit-transform: scale(0.6);
                  transform: scale(0.6);
          margin-top: 2rem; } }

.styles_container__3-pqY {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (max-width: 1199px) {
    .styles_container__3-pqY {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .styles_container__3-pqY .styles_left__1XTkU,
  .styles_container__3-pqY .styles_right__2KnHr {
    width: 45%; }
    @media screen and (max-width: 1199px) {
      .styles_container__3-pqY .styles_left__1XTkU,
      .styles_container__3-pqY .styles_right__2KnHr {
        width: unset; } }
  @media screen and (max-width: 1199px) {
    .styles_container__3-pqY .styles_left__1XTkU {
      margin-bottom: 20px; } }
  .styles_container__3-pqY .styles_right__2KnHr .styles_item__hZ-K6 {
    margin-bottom: 15px; }
    @media screen and (max-width: 1199px) {
      .styles_container__3-pqY .styles_right__2KnHr .styles_item__hZ-K6 {
        margin-bottom: 20px; } }
    .styles_container__3-pqY .styles_right__2KnHr .styles_item__hZ-K6 .styles_progressBar__ZUswj {
      margin-top: 35px; }

.styles_container__32vab {
  display: -webkit-flex;
  display: flex;
  color: white;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (max-width: 1199px) {
    .styles_container__32vab {
      -webkit-flex-direction: column;
              flex-direction: column;
      padding: 0 25px; } }
  @media screen and (max-width: 1199px) {
    .styles_container__32vab .styles_right__c18MT {
      margin-top: 45px; } }
  .styles_container__32vab .styles_left__2DOT9,
  .styles_container__32vab .styles_right__c18MT {
    width: 48%; }
    @media screen and (max-width: 1199px) {
      .styles_container__32vab .styles_left__2DOT9,
      .styles_container__32vab .styles_right__c18MT {
        width: unset; } }
    .styles_container__32vab .styles_left__2DOT9 > .styles_title__3qqPI,
    .styles_container__32vab .styles_right__c18MT > .styles_title__3qqPI {
      max-width: 100%;
      text-transform: uppercase;
      font-family: 'Roboto-Bold';
      margin-bottom: 30px;
      font-size: 48px;
      padding-left: 25px; }
      @media screen and (max-width: 1199px) {
        .styles_container__32vab .styles_left__2DOT9 > .styles_title__3qqPI,
        .styles_container__32vab .styles_right__c18MT > .styles_title__3qqPI {
          margin-bottom: 45px;
          line-height: 45px;
          word-spacing: 1000px;
          padding-left: unset; } }

.styles_insight__3bEhC {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  line-height: 26px;
  background-color: #1c1c1c;
  border-radius: 6px;
  box-shadow: 3px 3px 10px black;
  padding: 30px;
  margin-bottom: 20px; }
  @media screen and (max-width: 1199px) {
    .styles_insight__3bEhC {
      background-color: unset;
      box-shadow: unset;
      padding: unset; } }
  .styles_insight__3bEhC > .styles_title__3qqPI {
    text-transform: uppercase;
    font-family: 'Roboto-Bold';
    color: #f9b233;
    margin-bottom: 15.5px;
    font-size: 16px; }
  .styles_insight__3bEhC > .styles_content__2NmqI {
    min-height: 50px; }

.styles_sidebar__26JUp {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  max-width: 170px;
  padding-left: 20px;
  padding-top: 250px;
  background-color: #262626;
  box-shadow: 10px 10px 10px black; }
  @media screen and (max-width: 1199px) {
    .styles_sidebar__26JUp {
      display: none; } }

.styles_continueWrapper__1BMJZ {
  padding-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  @media screen and (max-width: 991px) {
    .styles_continueWrapper__1BMJZ {
      -webkit-justify-content: center;
              justify-content: center; } }
  .styles_continueWrapper__1BMJZ .styles_continueContainer__1cRIm {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-bottom: 30px; }
    @media screen and (max-width: 991px) {
      .styles_continueWrapper__1BMJZ .styles_continueContainer__1cRIm {
        margin-bottom: unset; } }
    .styles_continueWrapper__1BMJZ .styles_continueContainer__1cRIm .styles_arrow__37Sy_ {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.14);
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 10px; }

.styles_container__3LlK4 {
  color: white;
  line-height: 26px;
  max-width: 1000px;
  height: 100%; }
  .styles_container__3LlK4 .styles_title__38r8F {
    padding-bottom: 2rem;
    font-size: 40px;
    line-height: 1; }
  .styles_container__3LlK4 .styles_content__22cMZ img {
    max-width: 100%; }

.styles_container__3EY52 {
  color: white;
  line-height: 26px;
  max-width: 1000px;
  height: 100%; }
  .styles_container__3EY52 .styles_title__3Vioz {
    padding-bottom: 2rem;
    font-size: 40px;
    line-height: 1; }

@font-face {
  font-family: 'Roboto-Black';
  src: url(/static/media/Roboto-Black.3234b3e8.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url(/static/media/Roboto-BlackItalic.18a576bf.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(/static/media/Roboto-Bold.7c181887.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url(/static/media/Roboto-BoldItalic.9098d3de.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url(/static/media/Roboto-Italic.9da6e8b1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url(/static/media/Roboto-Light.c6cdfded.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url(/static/media/Roboto-Light.c6cdfded.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url(/static/media/Roboto-Medium.d52f011b.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url(/static/media/Roboto-LightItalic.afd48b81.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url(/static/media/Roboto-Regular.18d44f79.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url(/static/media/Roboto-Thin.d1e42f1b.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url(/static/media/Roboto-ThinItalic.2cbc2256.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --blue: #10a1f2;
  --grey: #83878f;
  --screen-sm-min: 768px;
  --screen-xs-max: (var(--screen-sm-min) - 1);
  --screen-md-min: 992px;
  --screen-sm-max: (var(--screen-md-min) - 1);
  --screen-lg-min: 1200px;
  --screen-md-max: (var(--screen-lg-min) - 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table td {
  word-break: break-word;
}

