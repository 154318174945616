@import 'assets/media_variables.scss';

.Page {
  background-color: #222222;
}

.PageBody {
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.PageContent {
  max-width: 1300px;
  padding-top: 270px;
  padding-right: 65px;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 1650px) {
    padding-top: 230px;
    padding-left: 210px;
  }

  @media screen and (max-width: $screen-md-max) {
    display: flex;
    justify-content: center;
    padding: 150px 25px 25px 25px;
    flex-direction: column;
    min-width: 100vw;
    max-width: 100vw;
  }

  @media screen and (max-width: $screen-xs-max) {
    padding: 150px 10px 25px 10px;
  }
}
