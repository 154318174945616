@import 'assets/media_variables.scss';

.container {
  .title {
    margin-top: 35px;

    @media screen and (max-width: $screen-md-max) {
      margin-top: unset;
      font-size: 48px;
      text-transform: uppercase;
      line-height: 45px;
    }
  }

  .text {
    margin-top: 65px;
    max-width: 50%;

    @media screen and (max-width: $screen-md-max) {
      max-width: 100%;
    }

    img {
      @media screen and (max-width: $screen-md-max) {
        max-width: 100%;
      }
    }
  }
}
