.field {
  color: white;
  display: block;
  min-width: 100%;
  border-radius: 20px;
  padding: 12px 24px;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  margin-top: 20px;
  background-color: transparent !important;
  outline: none;

  &::placeholder {
    color: white;
  }

  &:focus {
    box-shadow: 0 0 3px #ddd;
  }
}

.error {
  color: red;
  padding: 5px 10px 0 10px;
}
