@import 'assets/media_variables.scss';

.sidebar {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  max-width: 170px;
  padding-left: 20px;
  padding-top: 250px;
  background-color: #262626;
  box-shadow: 10px 10px 10px black;

  @media screen and (max-width: $screen-md-max) {
    display: none;
  }
}

.continueWrapper {
  padding-top: 2rem;

  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: $screen-sm-max) {
    justify-content: center;
  }

  .continueContainer {
    display: flex;
    align-items: center;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    user-select: none;
    margin-bottom: 30px;

    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: unset;
    }

    .arrow {
      width: 50px;
      height: 50px;

      border-radius: 50%;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.14);

      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 10px;
    }
  }
}
