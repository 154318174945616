@import 'assets/media_variables.scss';

.container {
  padding-top: 65px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
    padding-top: unset;
  }

  .left {
    flex-basis: 45%;

    .titleContainer {
      margin-bottom: 45px;
      white-space: nowrap;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 20px;
      }

      .icon {
        height: 60px;
        margin-right: 10px;

        @media screen and (max-width: $screen-md-max) {
          display: none;
        }
      }
    }

    .sectionTitle {
      color: white;
      margin-bottom: 45px;
      font-family: 'Roboto-Bold';
      font-size: 22px;
      line-height: 26px;

      @media screen and (max-width: $screen-md-max) {
        display: none;
      }
    }

    .sectionDescription {
      @media screen and (max-width: $screen-md-max) {
        margin: 30px 0 60px 0;
      }
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 30px;
      color: white;
      font-size: 14px;

      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .right {
    flex-basis: 45%;

    @media screen and (max-width: $screen-md-max) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .chartAdditional {
      @media screen and (min-width: $screen-md-max) {
        display: none;
      }
      .description {
        margin: 20px 0;
      }
    }

    .item {
      margin-top: 1rem;
      max-width: 400px;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 55px;
        width: 100%;
        max-width: unset;
      }
    }

    .lineChartItem {
      margin-top: 2rem;
      cursor: pointer;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 55px;
      }

      @media screen and (max-width: $screen-xs-max) {
        transform: scale(0.6);
      }
    }
  }
}
