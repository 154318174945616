.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  color: white;
  font-size: 14px;

  .item {
    display: flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    text-transform: capitalize;
  }
}
