@import 'assets/media_variables.scss';

.Page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: cover;
  height: 100%;
  background-attachment: fixed;
  background-image: url('assets/background.png');

  &:before {
    content: '';
    height: 110vh;
    position: fixed;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;

    @media screen and (max-width: $screen-md-max) {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.PageBody {
  padding-top: 120px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  flex-direction: column;
  min-height: 100%;
  z-index: 2;

  @media screen and (max-width: $screen-md-max) {
    padding-top: 50px;
  }
}

.PageContent {
  width: 100%;
  z-index: 2;
  max-width: 1428px;
  padding: 0 30px;
  box-sizing: border-box;

  @media screen and (max-width: 1100px) {
    width: 100%;
    padding: 35px 50px;
  }

  @media screen and (max-width: $screen-xs-max) {
    padding: 35px 25px;
  }
}
