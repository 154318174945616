@import 'assets/media_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: -50px;

  .formWrapper {
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 380px;
    width: 100%;

    .logo {
      max-width: 250px;
    }

    .title {
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      padding-bottom: 30px;
    }

    .subLink {
      margin-top: 20px;
      cursor: pointer;
      font-size: 14px;
      color: white;
      text-decoration: underline;
    }
  }
}
