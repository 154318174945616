@import 'assets/colors.scss';
@import 'assets/media_variables.scss';

.wrapper {
  width: 100%;
  max-height: 45px;
  overflow: hidden;

  &.desktop {
    @media screen and (max-width: $screen-md-max) {
      display: none;
    }
  }

  &.mobile {
    @media screen and (min-width: $screen-md-max) {
      display: none;
    }
  }

  &.active {
    order: 0;
    overflow: unset;
    padding-bottom: 100px;
    animation: slideUp 0.5s;

    .barContainer {
      background-color: rgba(245, 247, 251, 0.5);
    }
  }

  &.disabled {
    order: 2;
    opacity: 0.3;
  }

  .barContainer {
    cursor: pointer;
    width: 100%;
    height: 45px;
    background-color: rgba(245, 247, 251, 0.6);
    border-radius: 300px;
    display: flex;

    .progress {
      height: 99%;
      display: flex;
      min-width: 20px;
      border-radius: 300px;
      box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.16);
      animation: spread 2s;

      &.blue {
        background-color: $blue;
      }

      &.green {
        background-color: $green;
      }

      &.pink {
        background-color: $pink;
      }

      &.orange {
        background-color: $orange;
      }

      &.yellow {
        background-color: $yellow;
      }

      .text {
        margin-left: 30px;
        line-height: 45px;
        text-transform: uppercase;
        animation: slideRight 2s;
        color: white;
        user-select: none;
        position: absolute;
      }
    }
  }

  .textContainer {
    display: flex;
    margin-top: 8.5px;
    justify-content: space-between;
    color: white;
    user-select: none;
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;
    margin-left: -75px;
  }
}

@keyframes spread {
  0% {
    width: 0;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0.3;
    padding-bottom: 0;
  }
}
