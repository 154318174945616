@import 'assets/media_variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  color: white;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
  }

  .left {
    width: 45%;

    @media screen and (max-width: $screen-md-max) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .right {
    width: 45%;

    @media screen and (max-width: $screen-md-max) {
      width: 100%;
    }

    .abilityItem {
      margin-bottom: 15px;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 20px;
      }

      .progressBar {
        margin-top: 35px;
      }
    }
  }
}
