@import 'assets/media_variables.scss';

.wrapper {
  z-index: 4;
  width: 100%;
  color: white;
  height: 122px;
  display: flex;
  position: fixed;
  padding: 40px 0;
  justify-content: center;
  background-color: #191919;
  box-shadow: 0px 10px 10px black;

  @media screen and (max-width: $screen-md-max) {
    height: 125px;
    padding: 36px 25px 18px 25px;
  }

  @media screen and (max-width: $screen-xs-max) {
    height: 125px;
    padding: 36px 0 18px 0;
  }

  .container {
    width: 100%;
    display: flex;
    padding: 0 160px;
    justify-content: space-between;

    @media screen and (max-width: $screen-md-max) {
      padding: 0 20px;
      flex-direction: column;
    }

    .left {
      display: flex;
      align-items: center;
      width: 100%;

      & > * {
        @media screen and (max-width: $screen-md-max) {
          flex-basis: 33.33%;
        }
      }
      .logo {
        height: 100%;
        cursor: pointer;
        margin-right: 60px;
        max-height: 42.55px;

        @media screen and (max-width: $screen-md-max) {
          margin-right: unset;
        }
      }

      .dropdown {
        @media screen and (min-width: $screen-md-max) {
          display: none;
        }

        @media screen and (max-width: $screen-md-max) {
          text-align: right;
        }

        @media screen and (max-width: $screen-xs-max) {
          font-size: 11px;
        }
      }
    }

    .mobileIcon {
      display: none;
      @media screen and (max-width: $screen-md-max) {
        display: block;

        &.active {
          z-index: -1;
        }
      }

      .icon {
        content: 'menu';
        font-family: 'Material Icons';
      }
    }

    .navLinks {
      @media screen and (max-width: $screen-md-max) {
        display: none;
      }
    }
    .right {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      white-space: nowrap;

      .greet {
        font-family: 'Roboto-Bold';
        width: 100%;
        text-align: right;

        @media screen and (max-width: $screen-md-max) {
          display: none;
        }
      }

      .desktopDropdown {
        @media screen and (max-width: $screen-md-max) {
          display: none;
        }
      }

      .additional {
        color: #f9b233;
        font-size: 12px;
        line-height: 26px;
        width: 100%;
        text-align: right;

        @media screen and (max-width: $screen-md-max) {
          text-align: center;
        }
      }
    }
  }
}

.sidebar {
  z-index: 2 !important;
}
