.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .tab {
    width: 100%;
    color: white;
    height: 34px;
    font-size: 12px;
    text-align: left;
    max-width: 149px;
    line-height: 26px;
    user-select: none;
    padding-left: 27px;
    border-radius: 2px;
    margin-bottom: 15px;
    text-transform: uppercase;

    &.active,
    &:hover {
      cursor: pointer;
      font-family: 'Roboto-Bold';
      box-shadow: 4px 3px 4px #000000;
    }
  }

  .divider {
    width: 85%;
    margin-top: 10px;
    margin-bottom: 15px;
    align-self: flex-start;
    border-bottom: 1px solid white;
  }
}
