@import 'assets/media_variables.scss';

.container {
  width: 100%;
  .form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 380px;
    width: 100%;

    @media screen and (max-width: $screen-md-max) {
      padding: 0 10px;
    }

    .submit {
      text-transform: uppercase;
      height: 40px;
      width: 100%;
      border-radius: 20px;
      border: 0px;
      background-color: #f9b233;
      font-size: 16px;
      color: white;
      margin-top: 20px;

      &:disabled {
        cursor: not-allowed;
        filter: brightness(0.7);
      }
    }
  }
}
