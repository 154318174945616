@import 'assets/media_variables.scss';

.boxWrapper {
  padding: 30px 23px;
  box-shadow: 10px 10px 3px 0px rgba(0, 0, 0, 0.26);
  background-color: #1c1c1c;
  border-radius: 6px;

  .title {
    color: #f9b233;
    font-size: 22px;
    line-height: 18px;
    font-family: 'Roboto-Bold';
    padding-bottom: 18px;
  }

  .content {
    color: white;
    line-height: 26px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1440px) {
      max-width: 450px;
    }

    @media screen and (max-width: $screen-md-max) {
      max-width: unset;
    }
  }
}
