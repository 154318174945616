@import 'assets/media_variables.scss';

.container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
  }

  .left,
  .right {
    width: 45%;

    @media screen and (max-width: $screen-md-max) {
      width: unset;
    }
  }

  .left {
    @media screen and (max-width: $screen-md-max) {
      margin-bottom: 20px;
    }
  }

  .right {
    .item {
      margin-bottom: 15px;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 20px;
      }

      .progressBar {
        margin-top: 35px;
      }
    }
  }
}
