@import 'assets/media_variables.scss';

.container {
  padding-top: 65px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
    padding-top: unset;
  }

  .left {
    flex-basis: 50%;

    .title {
      white-space: nowrap;
      margin-bottom: 45px;

      @media screen and (max-width: $screen-md-max) {
        margin-bottom: 20px;
      }

      .icon {
        height: 60px;
        margin-right: 10px;

        @media screen and (max-width: $screen-md-max) {
          display: none;
        }
      }
    }

    .subTitle {
      color: white;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 25px;
      font-family: 'Roboto-Bold';
      text-transform: capitalize;
    }
  }

  .right {
    width: 100%;
    max-width: 400px;
    padding-top: 120px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $screen-md-max) {
      padding-top: 5px;
      max-width: unset;
    }

    .progressBarAdditional {
      @media screen and (min-width: $screen-md-max) {
        display: none;
      }
      .title {
        margin-top: 60px;
        margin-bottom: 20px;
        text-transform: capitalize;
      }
    }

    .progressBar {
      margin-bottom: 10px;

      @media screen and (max-width: $screen-md-max) {
        margin-top: 20px;
      }
    }
  }
}
