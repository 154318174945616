.wrapper {
  margin-top: 20px;
  .tabContainer {
    display: flex;
    overflow: hidden;
  }

  .tabContent {
    margin-top: 20px;
  }
}
